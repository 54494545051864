import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { Dropdown } from '@ucheba/ui/components/Dropdown/desktop'
import { Logo } from '@ucheba/ui/components/Logo/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Navigation } from '@ucheba/ui/components/Navigation/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Container } from '@ucheba/ui/components/Container/desktop'
import MarkerIcon from '@ucheba/ui/icons/icon__marker.svg'
import GraduationCapIcon from '@ucheba/ui/icons/icon__graduation-cap.svg'
import FemaleProfileIcon from '@ucheba/ui/icons/icon__female-profile.svg'
import LoginIcon from '@ucheba/ui/icons/icon__login.svg'
import { getLogoColor, useAuthContent } from '../bll'
import HeaderMenu from './HeaderMenu'
import { headerNavigation } from '../constants'
import { IHeaderProps } from '../types'
import { headerSelectors } from '../store'
import { useChooseLocationDialogCore, useLocationText } from '../../ChooseLocation/bll'
import SC from './Header.styles'

const {
  Block,
  Main,
  MainLineLeft,
  MainLineRight,
  LogoLink,
  HeaderNavItem,
  MainContainer,
  Circle,
} = SC

/** Шапка сайта */
const Header: FC<IHeaderProps> = (props) => {
  const { activeMenuItemHref } = props
  const color = useSelector(headerSelectors.color)
  const fade = useSelector(headerSelectors.fade)
  const width = useSelector(headerSelectors.width)
  const isShowNavigation = useSelector(headerSelectors.isShowNavigation)
  const isShowHeader = useSelector(headerSelectors.isShowHeader)
  const { asPath, pathname, push } = useRouter()

  const [isRenderCircle, setIsRenderCircle] = useState(false)

  useEffect(() => {
    setIsRenderCircle(!localStorage.getItem('wantToStudyButtonStepsShow'))
  }, [])

  const { childNavigation = [], name: activeItemName } = useMemo(() => {
    if (activeMenuItemHref) {
      return (
        headerNavigation.find(({ href }) => activeMenuItemHref === href) ||
        headerNavigation[0]
      )
    }
    return headerNavigation.find(({ href }) => pathname === href) || headerNavigation[0]
  }, [activeMenuItemHref, pathname])

  const { userName, isAuth, authLinkHref } = useAuthContent(pathname)

  const isNavigationItemActive = useMemo(() => {
    return childNavigation.findIndex((nav) =>
      nav.paths ? nav.paths.includes(pathname) : pathname === nav.href
    )
  }, [childNavigation, pathname])

  const { extendedHeaderOpenDialog } = useChooseLocationDialogCore()
  const { headerText } = useLocationText()

  const isPlanner = asPath.includes('/for-abiturients/planner')

  if (!isShowHeader) return null

  return (
    <Block {...props}>
      <Main color={color}>
        <MainContainer as={Container}>
          <MainLineLeft>
            <LogoLink href='/' aria-label='Главная страница'>
              <Logo color={getLogoColor(color)} />
            </LogoLink>

            <Dropdown
              menu={
                <HeaderMenu sectionNavigation={headerNavigation} fontWeight='medium' />
              }
              title={activeItemName}
              color={color}
            />
          </MainLineLeft>

          <MainLineRight>
            <HeaderNavItem color={color} onClick={extendedHeaderOpenDialog}>
              <MarkerIcon />

              <Text as='span'>{headerText}</Text>
            </HeaderNavItem>

            <HeaderNavItem
              color={color}
              active={isPlanner}
              onClick={() => push('/for-abiturients/planner')}
            >
              <GraduationCapIcon />

              <Text as='span'>План поступления</Text>

              {isRenderCircle && <Circle id='admissionPlanButtonCircle' />}
            </HeaderNavItem>

            <HeaderNavItem color={color} onClick={() => push(authLinkHref)}>
              {isAuth ? (
                <>
                  <FemaleProfileIcon data-test-id='profile-button' />

                  {userName && <Text as='span'>{userName}</Text>}
                </>
              ) : (
                <>
                  <LoginIcon data-test-id='login-button' />

                  <Text as='span'>Войти</Text>
                </>
              )}
            </HeaderNavItem>
          </MainLineRight>
        </MainContainer>
      </Main>

      {isShowNavigation && (
        <>
          <Spacing spacing='small' />

          <Container>
            <Navigation
              width={width}
              fade={fade}
              items={childNavigation}
              activeIndex={isNavigationItemActive}
              color={color}
              withRichSnippet
            />
          </Container>
        </>
      )}
    </Block>
  )
}

export default memo(Header)
