const forAbiturientsPath = '/for-abiturients'
const forClientsPath = '/for-clients'
const forTeachersPath = '/for-teachers'
const egePath = `${forAbiturientsPath}/ege`
const plannerPath = `${forAbiturientsPath}/planner`

export const forAbiturients = {
  path: forAbiturientsPath,
  ege: {
    path: `${forAbiturientsPath}/ege`,
    info: {
      path: `${egePath}/info`,
      subject: {
        path: `${egePath}/info/[subject]`,
      },
    },
    subject: {
      path: `${egePath}/[subject]`,
    },
    school: {
      path: `${egePath}/school`,
    },
    selectVuz: {
      path: `${egePath}/select-vuz`,
    },
    chooseSubjects: {
      path: `${egePath}/choose-subjects`,
    },
    howToPlan: {
      path: `${egePath}/how-to-plan`,
    },
    howToStart: {
      path: `${egePath}/how-to-start`,
    },
    howToCheck: {
      path: `${egePath}/how-to-check`,
    },
    rulesOnExam: {
      path: `${egePath}/rules-on-exam`,
    },
    howToRetake: {
      path: `${egePath}/how-to-retake`,
    },
    afterCollege: {
      path: `${egePath}/after-college`,
    },
    foreigners: {
      path: `${egePath}/foreigners`,
    },
    schedule: {
      path: `${egePath}/schedule`,
    },
    results: {
      path: `${egePath}/results`,
    },
    minimumScores: {
      path: `${egePath}/minimum-scores`,
    },
  },
  'ege-10': {
    path: `${forAbiturientsPath}/ege-10`,
    subject: {
      path: `${forAbiturientsPath}/ege-10/[subject]`,
    },
  },
  oge: {
    path: `${forAbiturientsPath}/oge`,
  },
  vuz: {
    path: `${forAbiturientsPath}/vuz`,
  },
  college: {
    path: `${forAbiturientsPath}/college`,
  },
  speciality: {
    path: `${forAbiturientsPath}/speciality`,
    search: {
      path: `${forAbiturientsPath}/speciality/search`,
    },
  },
  olympiads: {
    path: `${forAbiturientsPath}/olympiads`,
  },
  prof: {
    path: `${forAbiturientsPath}/prof`,
  },
  planner: {
    path: `${forAbiturientsPath}/planner`,
    interests: {
      path: `${plannerPath}/interests`,
    },
    specialties: {
      path: `${plannerPath}/specialties`,
    },
    programs: {
      path: `${plannerPath}/programs`,
    },
    plan: {
      path: `${plannerPath}/plan`,
    },
    about: {
      path: `${plannerPath}/about`,
    },
  },
  consult: {
    path: `${forAbiturientsPath}/consult`,
  },
  school: {
    path: `${forAbiturientsPath}/school`,
  },
  admissionSupport: {
    path: `${forAbiturientsPath}/admission-support`,
  },
}

export const prof = {
  path: '/prof',
  search: {
    path: `/prof/search`,
  },
  program: {
    path: `${forAbiturientsPath}/prof`,
  },
}

export const olymp = {
  path: `${forAbiturientsPath}/olympiads-new`,
  search: {
    path: `${forAbiturientsPath}/olympiads-new/search`,
  },
}

export const uz = {
  path: '/uz',
}

export const login = {
  path: '/auth',
}

export const marathon = {
  path: '/events/prof-marathon',
  agreement: {
    path: '/events/prof-marathon/agreement',
  },
}

export const forClients = {
  path: forClientsPath,
}

export const forTeachers = {
  path: forTeachersPath,
}
