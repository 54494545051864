import { forAbiturients, prof, forClients, forTeachers } from '../../routs'

export const headerHeight = {
  desktop: 102,
  touch: 64,
}

export const headerNavigation = [
  {
    name: 'Старшекласснику и&nbsp;абитуриенту',
    href: forAbiturients.path,
    spa: false,
    childNavigation: [
      {
        name: 'Подготовка к ЕГЭ и ОГЭ',
        href: forAbiturients.ege.path,
        spa: true,
      },
      {
        name: 'Профориентация',
        href: prof.program.path,
        spa: true,
      },
      {
        name: 'Сопровождение при поступлении',
        href: forAbiturients.admissionSupport.path,
        spa: true,
      },
      {
        name: 'Вузы',
        href: forAbiturients.vuz.path,
      },
      {
        name: 'Колледжи',
        href: forAbiturients.college.path,
      },
      {
        name: 'Специальности',
        href: forAbiturients.speciality.path,
      },
      {
        name: 'Профессии',
        href: '/prof',
      },
      {
        name: 'Олимпиады',
        href: forAbiturients.olympiads.path,
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        name: 'Дни открытых дверей',
        href: '/for-abiturients/events',
      },
      {
        name: 'Рейтинги',
        href: '/for-abiturients/vuz/rankings',
      },
      {
        name: 'Статьи',
        href: '/for-abiturients/articles',
      },
    ],
  },
  {
    name: 'Обучение для&nbsp;взрослых',
    href: '/for-specialists',
    spa: false,
    childNavigation: [
      {
        name: 'Курсы',
        href: '/for-specialists/courses',
      },
      {
        name: 'Тренинги и семинары',
        href: '/for-specialists/lectures',
      },
      {
        name: 'MBA',
        href: '/for-specialists/mba',
      },
      {
        name: 'Второе высшее',
        href: '/for-specialists/second-higher',
      },
      {
        name: 'Магистратура',
        href: '/for-specialists/master-programs',
      },
      {
        name: 'Аспирантура',
        href: '/for-specialists/postgraduate',
      },
      {
        name: 'Дни открытых дверей',
        href: '/for-specialists/events',
      },
    ],
  },
  {
    name: 'Обучение для&nbsp;детей',
    href: '/for-kids',
    spa: false,
    childNavigation: [
      {
        name: 'Школы',
        href: '/for-kids/schools',
      },
      {
        name: 'Курсы для детей',
        href: '/for-kids/courses',
      },
      {
        name: 'Статьи',
        href: '/for-kids/articles',
      },
      {
        name: 'Дни открытых дверей',
        href: '/for-kids/uz-opendoors',
      },
    ],
  },
  {
    name: 'Обучение за&nbsp;рубежом',
    href: '/abroad',
    spa: false,
    childNavigation: [
      {
        name: 'Учебные заведения',
        href: '/abroad/search',
      },
      {
        name: 'Образовательные агентства',
        href: '/abroad/agencies',
      },
      {
        name: 'Статьи',
        href: '/abroad/articles',
      },
    ],
  },
  {
    name: 'Учебным заведениям',
    href: forClients.path,
    spa: true,
  },
  {
    name: 'Репетиторам',
    href: forTeachers.path,
    spa: true,
  },
]
